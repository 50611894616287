var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-form-group",
    { attrs: { invalid: _vm.form.$errors.any() } },
    [
      _c(
        "gov-label",
        { staticClass: "govuk-!-font-weight-bold", attrs: { for: _vm.id } },
        [_vm._t("label", [_vm._v(_vm._s(_vm.label))])],
        2
      ),
      _vm._t("hint", [
        _vm.hint
          ? _c("gov-hint", {
              attrs: { for: _vm.id },
              domProps: { textContent: _vm._s(_vm.hint) }
            })
          : _vm._e()
      ]),
      _c("gov-file-upload", {
        ref: "file",
        attrs: {
          value: _vm.form.file,
          id: _vm.id,
          name: _vm.id,
          accept: _vm.accept
        },
        on: { change: _vm.onChangeFile }
      }),
      _vm._t("after-input"),
      _vm.form.$submitting
        ? _c("ck-loader", [_vm._v("Uploading")])
        : _vm.form.file
        ? _c("img", {
            key: "UploadedImage::" + _vm._uid,
            staticClass: "ck-logo",
            attrs: { src: _vm.form.file, alt: _vm.form.alt_text }
          })
        : _vm._e(),
      _c("ck-text-input", {
        attrs: {
          value: _vm.form.alt_text || "",
          id: "altText",
          label: "Image description",
          hint: "Describe the image for visually impaired visitors",
          type: "text",
          error: _vm.form.$errors.get("alt_text")
        },
        on: { input: _vm.onChangeAltText }
      }),
      _vm.form.$errors.any()
        ? _c("gov-error-message", {
            attrs: { for: _vm.id },
            domProps: {
              textContent: _vm._s(
                _vm.form.$errors.get(["is_private", "mime_type", "file"])
              )
            }
          })
        : _vm._e(),
      _c(
        "transition",
        { attrs: { name: "fade-in-out" } },
        [
          _vm.success
            ? _c("gov-hint", { staticClass: "upload-success" }, [
                _vm._v("\n      Image updated\n    ")
              ])
            : _vm.form.file && !_vm.form.alt_text
            ? _c("gov-warning-text", [_vm._v("Image description is required")])
            : _vm.imageChanged
            ? _c("gov-warning-text", [
                _vm._v(
                  "\n      The image or description has changed, click\n      "
                ),
                _c("em", [
                  _vm._v(_vm._s(_vm.fileId ? "Update" : "Upload") + " file")
                ]),
                _vm._v(" to commit these changes\n    ")
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._t("after-error-message"),
      _c(
        "div",
        { staticClass: "govuk-!-margin-top-2" },
        [
          _c(
            "gov-button",
            {
              attrs: {
                type: "button",
                disabled:
                  !_vm.form.file || !_vm.form.alt_text || !_vm.form.mime_type
              },
              on: { click: _vm.onUpload }
            },
            [_vm._v(_vm._s(_vm.fileId ? "Update" : "Upload") + " file")]
          ),
          _vm._v(" \n    "),
          _vm.form.file
            ? _c(
                "gov-button",
                {
                  attrs: { type: "button", error: "" },
                  on: { click: _vm.onRemove }
                },
                [_vm._v("Remove file")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }